import useTranslation from "sholdi-hooks/useTranslation";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Search from "sholdi-icons/Search";
import Text from "sholdi-primitives/atoms/Text";
import CircleIcon from "../../molecules/CircleIcon";

const SIZES = {
  default: {
    wrapperSize: "size-16",
    iconWrapperSize: "size-11",
    iconSize: "size-5",
    textSize: "text-base lg:text-base",
    subTextSize: "text-sm lg:text-sm",
  },
  lg: {
    wrapperSize: "size-18",
    iconWrapperSize: "size-13",
    iconSize: "size-7",
    textSize: "text-lg lg:text-lg",
    subTextSize: "text-base lg:text-base",
  },
};

const DataPlaceholder = ({
  icon: Icon,
  text,
  subText,
  children,
  color,
  translationParameters,
  size = "default",
  showIcon = true,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const { wrapperSize, iconWrapperSize, textSize, subTextSize, iconSize } =
    SIZES[size] || SIZES.default;

  return (
    <div
      className={twMerge(
        "flex items-center flex-col justify-center w-full lg:w-96 mx-auto py-8 text-center text-gray-300 mb-4",
        className,
      )}
      {...props}
    >
      {showIcon && (
        <CircleIcon
          icon={Icon}
          color={color}
          className={twMerge("mb-4", wrapperSize)}
          innerCircleClassName={iconWrapperSize}
          iconProps={{ className: iconSize }}
        />
      )}
      <Text className={twMerge("text-center text-gray-900 mb-1", textSize)}>
        {t(text, translationParameters)}
      </Text>
      {subText && (
        <Text
          className={twMerge("text-center text-gray-600 mb-6", subTextSize)}
        >
          {t(subText)}
        </Text>
      )}
      {children}
    </div>
  );
};

DataPlaceholder.displayName = "DataPlaceholder";

DataPlaceholder.defaultProps = {
  icon: Search,
  text: "noData",
  subText: "noDataDescription",
  color: "gray",
};

DataPlaceholder.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  subText: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.node,
  translationParameters: PropTypes.shape({}),
  size: PropTypes.oneOf(["default", "md", "lg"]),
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default DataPlaceholder;
